import '../styles/index.scss'
import 'swiper/css/swiper.css'

import barba from '@barba/core'
import gsap from 'gsap'
import Swiper from 'swiper'
import imagesLoaded from 'imagesloaded'

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

const transitions = [
  {
    name: 'enterHome',
    once({ next }) {
      enterHome(next)
    },
    beforeEnter({ current }) {
      return new Promise((resolve) => {
        gsap.to(current.container, {
          opacity: 0,
          onComplete: () => {
            current.container.remove()
            resolve()
          },
        })
      })
    },
    enter({ next }) {
      enterHome(next)
    },
    to: {
      namespace: ['home'],
    },
  },
  {
    name: 'enterGallery',
    once({ next }) {
      gsap.set(next.container, { opacity: 0 })
      gsap.to(next.container, { opacity: 1, delay: 1 })
    },
    enter({ next }) {
      gsap.set(next.container, { opacity: 0 })
      gsap.to(next.container, { opacity: 1 })
    },
    beforeLeave({ current }) {
      return new Promise((resolve) => {
        gsap.to(current.container, {
          opacity: 0,
          onComplete: () => {
            current.container.remove()
            resolve()
          },
        })
      })
    },
  },
]

const views = [
  {
    namespace: 'gallery',
    beforeEnter({ next }) {
      const { container } = next
      // Dom elements
      const swiperContainer = container.querySelector('.swiper-container')
      const swiperWrapper = container.querySelector('.swiper-wrapper')
      const nextBtn = container.querySelector('.gallery-next')
      const prevBtn = container.querySelector('.gallery-prev')
      const name = swiperContainer.getAttribute('data-name')

      // Set container width
      const imageRatio = 0.6996825397
      const setHeightWidth = () => {
        const screenHeight = window.innerHeight
        const screenWidth = window.innerWidth
        const width = Math.min(screenWidth, imageRatio * screenHeight)
        gsap.set(swiperContainer, { width })
      }
      window.addEventListener('resize', setHeightWidth)
      setHeightWidth()

      // Dataset
      const sources = [
        { name: 'gilze', count: 26, letter: 'G' },
        { name: 'rijen', count: 26, letter: 'R' },
        { name: 'hulten', count: 7, letter: 'H' },
        { name: 'molenschot', count: 7, letter: '' },
      ]
      const sourceIndex = sources.findIndex((source) => source.name === name)
      const source = sources[sourceIndex]

      // Set next / prev page
      let nextPage = ''
      let prevPage = ''
      if (sourceIndex === sources.length - 1) {
        nextPage = sources[0].name
      } else {
        nextPage = sources[sourceIndex + 1].name
      }
      if (sourceIndex === 0) {
        prevPage = sources[sources.length - 1].name
      } else {
        prevPage = sources[sourceIndex - 1].name
      }

      // Add slides to Dom
      let images = `
        <div class="swiper-slide">
          <div class="swiper-go-page">
            <span>Vorige</span>
            <h4>${prevPage}</h4>
          </div>
        </div>
      `
      for (let step = 1; step <= source.count; step++) {
        const src = `/images/BORD_BEVR_${source.name.toUpperCase()}_${
          source.letter
        }${step < 10 ? '0' : ''}${step}.jpg`
        const image = `
          <div class="swiper-slide">
            <img data-src="${src}" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div>
          </div>
        `
        images = images + image
      }
      images =
        images +
        `
        <div class="swiper-slide">
          <div class="swiper-go-page">
            <span>Volgende</span>
            <h4>${nextPage}</h4>
          </div>
        </div>
      `
      swiperWrapper.innerHTML = images

      // Init swiper
      const isDesktop = window.innerWidth > 760
      const swiper = new Swiper(swiperContainer, {
        lazy: true,
        direction: isDesktop ? 'vertical' : 'horizontal',
        initialSlide: 1,
      })
      swiper.on('slideChange', () => {
        const extension = IS_DEVELOPMENT ? '.html' : ''
        const delay = 800
        if (swiper.isBeginning) {
          setTimeout(() => {
            barba.go(`/${prevPage}${extension}`)
          }, delay)
        } else if (swiper.isEnd) {
          setTimeout(() => {
            barba.go(`/${nextPage}${extension}`)
          }, delay)
        }
      })
      nextBtn.addEventListener('click', () => swiper.slideNext())
      prevBtn.addEventListener('click', () => swiper.slidePrev())
      document.addEventListener('keyup', (e) => {
        if (e.keyCode === 37) {
          swiper.slidePrev()
        } else if (e.keyCode === 39) {
          swiper.slideNext()
        }
      })
    },
  },
]

const enterHome = (next) => {
  const { container } = next
  // Dom elements
  const homeWrapper = container.querySelector('.home-wrapper')
  const homeContentWrapper = container.querySelector('.home-content-wrapper')
  const homeContent = container.querySelector('.home-content')
  const navListItems = container.querySelectorAll('.home-content__items li')
  const logo = container.querySelector('.home-content__img')

  const imageRatio = 0.69

  const setHeightWidth = () => {
    const screenHeight = window.innerHeight
    const screenWidth = window.innerWidth
    if (screenWidth < 760) {
      gsap.set([homeContent, homeContentWrapper], { width: '100%' })
    } else {
      const width = screenWidth - screenHeight * imageRatio
      gsap.set([homeContent, homeContentWrapper], { width })
    }
  }
  window.addEventListener('resize', setHeightWidth)

  // Animation - on load
  imagesLoaded(container, () => {
    const screenHeight = window.innerHeight
    const screenWidth = window.innerWidth
    if (screenWidth >= 760) {
      const width = screenWidth - screenHeight * imageRatio
      gsap.to(homeContent, { width })
      gsap.to(homeContentWrapper, { width, delay: 0.3 })
    }
    gsap.to(navListItems, { opacity: 1, stagger: 0.1, delay: 0.2 })
    gsap.to(logo, { opacity: 1, delay: 0.7, duration: 2 })
  })
}

barba.init({
  transitions,
  views,
})
